import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";

const KidsSummerCampRegistrationSuccess = () => {
    return (
        <>
            <MetaData title="Registration Successful - KRISHNA KARNAMRITAM Kids Summer Camp 2025" />
            <PageHeader
                title="Registration Successful"
                imgSrc="/images/page-header/24.jpg"
            />
            <section className="registration-success-section py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="success-icon mb-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100"
                                    height="100"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="green"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <h2 className="mb-4">Hare Krishna! Thank You for Registering!</h2>
                            <div className="alert alert-success">
                                <p className="lead">
                                    Your child has been successfully registered for the KRISHNA KARNAMRITAM Kids Summer Camp 2025.
                                    Your registration and payment have been confirmed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default KidsSummerCampRegistrationSuccess;