import React, { useEffect, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Fancybox from "../helpers/Fancybox";
import { Link } from "react-router-dom";
import ApiService from "../api/ApiService";

const ShopList = () => {
  const [shopsList, setShopList] = useState([]);

  const getAllShopsList = async () => {
    try {
      const response = await ApiService.fetchAllShopsList();
      if (response.success) {
        setShopList(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllShopsList();
  }, []);

  return (
    <>
      <MetaData title="Shop - ISKCON of Bhiwandi" />
      <PageHeader title="SHOP" imgSrc="/images/page-header/24.jpg" />
      <section className="shop-wrap">
        <div className="container">
          <div className="row align-items-center mt-5 mb-5">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">Ramayana</h2>
                <p>The Rāmayana records the adventures of Rāma, the Lord of righteousness, as He struggles to overcome the forces of Rāvana. This is not a modern, imagined version of the Rāmāyana. It is the true account, concise but faithful to the original.</p>
                <Link to="https://iskcontsp.org/spiritual-shop/?wpf=language&wpf_cols=3&wpf_page=1&wpf_book-categories=ramayana" className="custom-btn-cls box-hover ms-0 px-4 py-2">
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Ramayana</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: false,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a href="/images/shop/Ramaya.jpg" data-fancybox="images">
                        <figure className="mb-0">
                          <img src="/images/shop/Ramaya.jpg" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
          {shopsList.length > 0 &&
            shopsList.map((list, index) => (
              <div className={"row align-items-center" + (index > 0 ? " mt-5" : "")} key={index}>
                <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
                  <div className="heading text-start">
                    <h2 className="head-1 d-none d-lg-block">{list.title}</h2>
                    <p>{list.description}</p>
                    <p className="mt-4 highlighted-text">Contains: {list.quantity}</p>
                    <Link to={"/shop-checkout/" + list._id} className="custom-btn-cls box-hover ms-0 px-4 py-2">
                      shop now
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-1">
                  {/* <div className="heading text-start mb-4 d-lg-none">
                    <h2 className="head-1">{list.title}</h2>
                  </div> */}
                  <div className="shop-slider-wrap">
                    <Splide
                      options={{
                        type: "loop",
                        rewind: true,
                        pagination: false,
                        arrows: false,
                        perPage: 1,
                        perMove: 1,
                        autoplay: true,
                        speed: 1000,
                        interval: 2000,
                        pauseOnHover: true,
                      }}
                    >
                      {list.galleryImages.map((image, i) => (
                        <SplideSlide>
                          <Fancybox>
                            <a href={process.env.REACT_APP_S3_URL + "/uploads/shop/gallery-images/" + image} data-fancybox="images">
                              <figure className="mb-0">
                                <img src={process.env.REACT_APP_S3_URL + "/uploads/shop/gallery-images/" + image} alt="shop-images" />
                              </figure>
                            </a>
                          </Fancybox>
                        </SplideSlide>
                      ))}
                    </Splide>
                  </div>
                </div>
              </div>
            ))}
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">Bhaktivedanta Book Store</h2>
                <p>Prabhupada’s books are store houses of light and here a select reading list has been made available to buy online.</p>

                <Link to="https://iskcontsp.org/spiritual-shop/" className="custom-btn-cls box-hover ms-0 px-4 py-2">
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Bhaktivedanta Book Store</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a href="/images/shop/bhaktivendantaa.webp" data-fancybox="images">
                        <figure className="mb-0">
                          <img src="/images/shop/bhaktivendantaa.webp" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopList;
