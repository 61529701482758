import React, { useRef, useState, useEffect } from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import notification from "../helpers/notification";
import axios from "axios";
import ApiService from "../api/ApiService";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const KrishnaQuizRegistration = () => {
    const [loading, setLoading] = useState(false);
    const reCaptChaRef = useRef();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            amount: 300,
        },
    });

    const fetchStateCity = async (pin) => {
        try {
            let url = `https://api.postalpincode.in/pincode/${pin}`;

            let config = {
                method: "GET",
                url,
            };
            const { data } = await axios(config);

            let cityVal = '';
            if (data[0].PostOffice.length > 1) {
                data[0].PostOffice.map(ct => {
                    if (ct.Block !== 'NA') {
                        return cityVal = ct.Block
                    }
                })
            } else {
                cityVal = data[0].PostOffice[0].Block;
            }

            if (data[0].Status === "Success") {
                setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
                setValue("state", data[0].PostOffice[0].State);
            } else {
                notification("warning", "Enter valid PIN code!");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleRegistration = async (values) => {
        try {
            setLoading(true);

            const token = await reCaptChaRef.current.executeAsync();
            values.token = token;
            values.amount = 300;
            values.quizContest = "Krishna Book Quiz";
            console.log("Va;ue", values);
            const res = await ApiService.registerQuizContest(values);

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                currency: res.currency,
                amount: res.amount,
                name: "ISKCON Attapur",
                description: "Krishna Book Quiz Contest Registration",
                image: `${process.env.REACT_APP_BACKEND_URL}/assets/img/logo.png`,
                order_id: res.id,
                handler: function (response) {
                    navigate('/quiz-registration-success', { replace: true });
                },
                prefill: {
                    name: values.name,
                    email: values.email,
                    contact: values.phone,
                },
                notes: {
                    type: "krishna-quiz",
                },
                theme: {
                    color: "#FFD370",
                },
                modal: {
                    ondismiss: async function () {
                        const cancelPurchase = await ApiService.cancelPurchase(res.id);
                    },
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

            paymentObject.on("payment.failed", function (response) {
                navigate(`/purchase-failed?id=${res.shopId}`, { replace: true });
            });

            setLoading(false);
            reCaptChaRef.current.reset();
        } catch (error) {
            setLoading(false);
            console.log(error);
            notification("error", error.response?.data?.message || "Registration failed");
        }
    };

    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = () => resolve(true);
                script.onerror = () => resolve(false);
                document.body.appendChild(script);
            });
        };

        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, []);

    return (
        <>
            <MetaData title="Krishna Book Quiz Contest Registration - ISKCON Attapur" />
            <PageHeader
                title="Krishna Book Quiz Contest Registration"
                imgSrc="/images/page-header/24.jpg"
            />
            <section className="donation-form-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">Krishna Book Quiz Contest Registration</h2>
                                <p className="b-line">
                                    Register for the online quiz contest based on "The Krishna Book"
                                    and stand a chance to win exciting prizes on Janmashtami!
                                </p>
                            </div>
                            <div className="quiz-details mt-4">
                                <p>✨🌸 <strong>Hare Krishna!</strong> 🌸✨</p>
                                <p>
                                    ISKCON Attapur is organizing an Online Quiz contest series based on <strong>“The Krishna Book”</strong> 🎉
                                </p>
                                <h4>📋 Quiz Details</h4>
                                <ul>
                                    <li><strong>Format:</strong> Multiple-choice</li>
                                    <li><strong>Language:</strong> English and Telugu</li>
                                    <li><strong>Mode:</strong> Online</li>
                                    <li>
                                        <strong>Quiz Schedule:</strong> Conducted every Sunday. Links will be opened and shared in the WhatsApp group every Sunday at 8 PM and will be closed within a limited time.
                                    </li>
                                    <li>
                                        <strong>Note:</strong> Chapters will be grouped for the quizzes, and participants will have one week to go through the assigned chapters before each quiz.
                                    </li>
                                </ul>
                                <h4>📢 Register & Join the WhatsApp Group!</h4>
                                <p>
                                    ✅ Fill out the registration form, and upon completion of payment, you will be added to the WhatsApp group to stay updated!
                                </p>
                                <p>
                                    🌈✨ Upon registration, a <strong>FREE copy of KRSNA BOOK</strong> can be collected from <strong>ISKCON ATTAPUR</strong> any day between <strong>10AM - 1:30PM & 4:30PM - 9PM</strong> or delivered to the address provided.
                                </p>
                                <p>
                                    🎉 Also, <strong>TOP 5 Winners</strong> will be awarded with some special gifts 🎁 on <strong>Janmashtami</strong>! 🌟🪷
                                </p>
                            </div>
                        </div>

                    </div>
                    <form className="shop-form mt-5" onSubmit={handleSubmit(handleRegistration)}>
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="donate-img">
                                    <figure className="mb-0">
                                        <img src="/images/common/krishna-quiz.webp" alt="Krishna Quiz Contest" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-lg-8 mt-lg-0 mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Your Name"
                                                {...register("name", { required: true })}
                                            />
                                            {errors.name?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="phone">Phone</label>
                                            <input
                                                type="tel"
                                                placeholder="Enter Your Phone"
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("phone", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^[6-9]\d*$/,
                                                        message: "Please provide valid phone number",
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "Phone number must be minimum 10 digit",
                                                    },
                                                })}
                                            />
                                            {errors.phone?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                            {errors.phone && (
                                                <span className="error text-danger">{errors.phone.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="whatsappNumber">WhatsApp Number</label>
                                            <input
                                                type="tel"
                                                placeholder="Enter WhatsApp Number"
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("whatsappNumber")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                placeholder="Enter Your Email"
                                                {...register("email", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Please enter a valid email",
                                                    },
                                                })}
                                            />
                                            {errors.email?.type === "required" && <span className="error text-danger">This is required</span>}
                                            {errors.email && <span className="error text-danger">{errors.email.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="flatNumber">Flat/House Number</label>
                                            <input
                                                type="text"
                                                placeholder="Flat/House Number"
                                                {...register("flatNumber", { required: true })}
                                            />
                                            {errors.flatNumber?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="apartmentName">Apartment/Street Name</label>
                                            <input
                                                type="text"
                                                placeholder="Apartment/Street Name"
                                                {...register("apartmentName", { required: true })}
                                            />
                                            {errors.apartmentName?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="pin">PIN</label>
                                            <input
                                                type="text"
                                                minLength={6}
                                                maxLength={6}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("pin", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^\d+$/,
                                                        message: "PIN code must be number.",
                                                    },
                                                    onChange: (e) => {
                                                        if (e.target.value.length === 6) {
                                                            fetchStateCity(e.target.value);
                                                        }
                                                    },
                                                })}
                                                placeholder="PIN"
                                            />
                                            {errors.pin?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                            {errors.pin && (
                                                <small className="text-danger error">{errors.pin.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-part">
                                            <label htmlFor="">State</label>
                                            <input
                                                type="text"
                                                placeholder="State"
                                                {...register('state')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="city">City</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Your City"
                                                {...register("city", { required: true })}
                                            />
                                            {errors.city?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                            ref={reCaptChaRef}
                                            size="invisible"
                                        />
                                    </div>
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                                        >
                                            {loading ? (
                                                <div className="d-flex align-items-center">
                                                    <div className="spinner-border text-light" role="status"></div>
                                                    <div className="ms-2">REGISTERING...</div>
                                                </div>
                                            ) : (
                                                `PAY ₹${300}/-`
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default KrishnaQuizRegistration;