import axios from "axios";
import history from "../history";

//Configuration
const fetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/",
  timeout: 60000,
});

fetch.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      config.headers["Authorization"] = "Bearer " + jwtToken;
    }
    if (!jwtToken && !config.headers["public-request"]) {
      history.push("/");
      window.location.reload();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fetch.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);

    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      history.push("/");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const ApiService = {};

//Donation services

ApiService.fetchDonationServices = () => {
  return fetch({
    url: "/donation-type",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationService = (slug) => {
  return fetch({
    url: `/donation-type/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.fetchYatraEnquiry = () => {
  return fetch({
    url: `/yatra/list`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchYatraEnq = (slug) => {
  return fetch({
    url: `/yatra/enq/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//----------------------------------------------

ApiService.getIcsData = (slug) => {
  return fetch({
    url: `/getIcsData/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getServiceData = (slug) => {
  return fetch({
    url: `/getServiceData/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};




ApiService.getIcsDonation = (slug) => {
  return fetch({
    url: `/getIcsDonation/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getIcsScheme = (slug) => {
  return fetch({
    url: `/getIcsScheme/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getIcsDonation_b = (slug) => {
  return fetch({
    url: `/getIcsDonation_b/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchNameFromIcs = (slug) => {
  return fetch({
    url: `/fetchNameFromIcs/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.sendDataToIcs = (details) => {
  return fetch({
    url: `/sendDataToIcs`,
    data: details,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.icsUpdate = (updateData) => {
  return fetch({
    url: `/icsUpdate`,
    data: updateData,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.createServicetoIcs = (Data) => {
  return fetch({
    url: `/createServicetoIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.createIndividualQuick = (Data) => {
  return fetch({
    url: `/createIndividualQuick`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.updateMaIcs = (Data) => {
  return fetch({
    url: `/updateMaIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};



ApiService.handelservRequest = (Data) => {
  return fetch({
    url: `/handelservRequest`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.joinFamilyrelationshipIcs = (Data) => {
  return fetch({
    url: `/joinFamilyrelationshipIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchJoinfamilyIcs = (Data) => {
  return fetch({
    url: `/fetchJoinfamilyIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.icsPanUpdateToIcs = (Data) => {
  return fetch({
    url: `/icsPanUpdateToIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};







//------------------------------------------------

ApiService.fetchYatraEnqUpdate = (slug) => {
  return fetch({
    url: `/yatra/enq/Update?data=${slug}`,
    data: slug,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.fetchPopularDonations = () => {
  return fetch({
    url: `/donation-type/popular`,
    headers: {
      "public-request": "true",
    },
  });
};

//Donation

ApiService.donate = (data) => {
  return fetch({
    url: "/donate",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

//CASHFREE DONATION API
ApiService.donateCsh = (data) => {
  return fetch({
    url: "/donate-csh",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.cashFreeFetchPayments = (orderId) => {
  return fetch({
    url: `/statuscsh/${orderId}`,
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.subscribeNityaSeva = (data) => {
  return fetch({
    url: "/subscribe_nityaSeva",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.subscribeNityaSevaAutopay = (data) => {
  return fetch({
    url: "/subscribe_nityaSeva_autopay",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.nityaSevaCancel = (data) => {
  return fetch({
    url: `/nityaSevaCancel`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.nityaSevaPause = (data) => {
  return fetch({
    url: `/nityaSevaPause`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};



// ApiService.icsdonate = (data) => {
//   return fetch({
//     url: "https://srv.konsoftech.in/ics/api/ah?oper=DONDATA&apikey=CFRcu3sMY0LrMkjGAUYrSIPJBSLvwniT",
//     method: "POST",
//     data,
//     headers: {
//       "public-request": "true",
//     },
//   });
// };

ApiService.donateCampaign = (data) => {
  return fetch({
    url: "/donate-campaign",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.donateCampaignCsh = (data) => {
  return fetch({
    url: "/donate-campaign-csh",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.download80g = (file) => {
  //  console.log(file)
  return fetch({
    url: `/download-80g/${file}`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.downloadNon80g = (details) => {
  return fetch({
    url: `/download-Non80g?data=${details}`,
    data: details,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadImg = (file) => {
  //  console.log(file)
  return fetch({
    url: `/download-downloadImg/${file}`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.check80g = (id, donationType) => {
  return fetch({
    url: `/check-80g/${id}/${donationType}`,
    headers: {
      "public-request": "true",
    },
  });
};

//Festivals

ApiService.fetchFestivals = () => {
  return fetch({
    url: "/festival/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Shedule

ApiService.fetchSheduleData = () => {
  return fetch({
    url: "/sheduled/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Bank Details

ApiService.fetchBankDetailsData = () => {
  return fetch({
    url: "/bank/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Yatras

ApiService.fetchYatras = () => {
  return fetch({
    url: "/yatra/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Eventss

ApiService.fetchEvents = () => {
  return fetch({
    url: "/event/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Eventss

ApiService.fetchDarshan = () => {
  return fetch({
    url: "/darshan/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadCalendar = (file) => {
  return fetch({
    url: `/festival/${file}/download-calendar`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};
//Why krishna

ApiService.fetchHomepageVideo = () => {
  return fetch({
    url: "/why-krishna/home",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchVideos = () => {
  return fetch({
    url: "/why-krishna",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Courses

ApiService.fetchCourses = () => {
  return fetch({
    url: "/courses",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Services

ApiService.fetchServices = () => {
  return fetch({
    url: "/services",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Enquire
ApiService.createEnquiry = (data) => {
  return fetch({
    url: "/enquiry/create",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.createYatraEnquiry = (data) => {
  return fetch({
    url: "/yatra/enquiry/create",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.newConnect = (data) => {
  return fetch({
    url: "/connect/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.newVolunteer = (data) => {
  return fetch({
    url: "/volunteer/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchWallpapers = () => {
  return fetch({
    url: "/wallpapers",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchPaymentDetails = (id, donationType) => {
  return fetch({
    url: `/fetch-payment-details/${id}/${donationType}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

// Fetch Meta Data
ApiService.fetchMetaData = (slug) => {
  return fetch({
    url: `/fetch-meta-data/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.joinFamily = (data) => {
  return fetch({
    url: `/join-family/new`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchCampaign = (slug) => {
  return fetch({
    url: `/fetch-campaign/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchCampaigns = () => {
  return fetch({
    url: `/fetch-campaigns`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.lifeMembership = (data) => {
  return fetch({
    url: "/life-membership",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.retryDonation = (id) => {
  return fetch({
    url: `/retry-payment/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.retryLifeMembership = (id) => {
  return fetch({
    url: `/retry-life-membership/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationDetails = (data) => {
  return fetch({
    url: `/fetch-donation-details/${data.id}?type=${data.type}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationDetailss = (id) => {
  return fetch({
    url: `/fetch-donation-details/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.updatePanDetails = (details) => {
  return fetch({
    url: `/update-pan-details?type=${details.type}`,
    data: details.data,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchFailedCampaignDetails = (id) => {
  return fetch({
    url: `/fetch-failed-campaign-details?id=${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendOtpp = (data) => {
  return fetch({
    url: `/send-otpp?number=${data.number}`,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendOtp = (data) => {
  return fetch({
    url: `/send-otp?number=${data.number}`,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.loginUser = (data) => {
  return fetch({
    url: `/login`,
    data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.loginUserr = (data) => {
  return fetch({
    url: `/loginn`,
    data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchUser = () => {
  return fetch({
    url: '/user',
    method: 'GET'
  })
}

ApiService.updateProfile = (data) => {
  return fetch({
    url: '/user-update',
    method: 'PUT',
    data,
  })
}

ApiService.fetchUserDonation = (page) => {
  return fetch({
    url: `/user-donations?page=${page}`,
    method: 'GET',
  })
}

ApiService.fetchUserDonationNitya = (page) => {
  return fetch({
    url: `/user-donationsnitya?page=${page}`,
    method: 'GET',
  })
}

ApiService.fetchJoinFamily = (phone) => {
  return fetch({
    url: `/fetch-join-family?phone=${phone}`,
    method: 'GET'
  })
}

ApiService.fetchLiveVideos = () => {
  return fetch({
    url: '/fetch-live-video',
    method: 'GET',
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.hasWatchLive = () => {
  return fetch({
    url: '/has-watch-live',
    method: 'GET',
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.cancelDonation = (orderId) => {
  return fetch({
    url: `/cancel-donation/${orderId}`,
    method: 'DELETE',
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.cancelLifeMembership = (orderId) => {
  return fetch({
    url: `/cancel-life-membership/${orderId}`,
    method: 'DELETE',
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.cancelCampaign = (orderId) => {
  return fetch({
    url: `/cancel-campaign/${orderId}`,
    method: 'DELETE',
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.fetchLifeMembership = () => {
  return fetch({
    url: '/fetch-life-membership',
    method: 'GET'
  })
}

ApiService.purchaseShopProduct = data => {
  return fetch({
    url: "/purchase-shop-product",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.purchasegauraPurimaProduct = data => {
  return fetch({
    url: "/purchase-gauraPurima-product",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.purchaseShopProductram = data => {
  return fetch({
    url: "/purchase-shop-product-ram",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.fetchPurchaseDetails = id => {
  return fetch({
    url: `/fetch-purchase-details/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.cancelPurchase = id => {
  return fetch({
    url: `/cancel-purchase/${id}`,
    method: "DELETE",
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.fetchUserPurchases = () => {
  return fetch({
    url: `/user-purchase`,
    method: "GET",
  })
}

ApiService.fetchBanner = (location) => {
  return fetch({
    url: `/banners?location=${location}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.fetchKartikDeepdaans = () => {
  return fetch({
    url: `/fetch-kartik-deepdaans`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.createKartikDeepdaanPayment = (data) => {
  return fetch({
    url: `/create-kartik-deepdaans-payment`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.occasionBook = (data) => {
  return fetch({
    url: "/occasionBook/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchTempleVideos = () => {
  return fetch({
    url: "/media-videos",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.report = (data) => {
  return fetch({
    url: "/report",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadReciept = (data) => {
  return fetch({
    url: `/downloadReciept`,
    data: data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchAllShopsList = () => {
  return fetch({
    url: `/fetch-all-shops-list`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  })
}

ApiService.fetchShopById = (id) => {
  return fetch({
    url: `/fetch-shop-list-by-id/` + id,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  })
}
ApiService.registerQuizContest = data => {
  return fetch({
    url: "/krishna-quiz-api",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  })
}
ApiService.registerKidsSummerCamp = data => {
  return fetch({
    url: "/kids-summer-camp",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  })
}


export default ApiService;

