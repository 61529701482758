import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@splidejs/react-splide/css";
import "./css/style.scss";
import Home from "./pages/Home";
import Donate from "./pages/Donate";
import DonationForm from "./pages/DonationForm";
import NityaSevaForm from "./pages/nityaSevaForm.jsx";
import Donorlogin from "./pages/donorLogin.jsx";
import Festivals from "./pages/Festivals";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import SideNav from "./components/common/SideNav";
import Movement from "./pages/Movement";
import Services from "./pages/Services";
import Yatras from "./pages/Yatras";
import Volunteer from "./pages/Volunteer";
import FoodForLife from "./pages/FoodForLife";
import ThankYou from "./pages/ThankYou";
import PaymentFailed from "./pages/PaymentFailed";
import PurchaseFailed from "./pages/PurchaseFailed";
import Contact from "./pages/Connect";
import AboutUs from "./pages/AboutUs";
import { HelmetProvider } from "react-helmet-async";
import GaudiaPage from "./pages/GaudiaPage";
import PageNotFound from "./pages/PageNotFound";
import Download80G from "./pages/Download80G";
import Courses from "./pages/Courses";
import KrishnaQuiz from "./pages/KrishnaQuizForm.jsx";
import KrishnaQuizRegistrationSuccess from './pages/KrishnaQuizRegistrationSuccess';
import KidsSummerCampRegistration from "./pages/KidsSummerCampRegistration.jsx";
import KidsSummerCampRegistrationSuccess from "./pages/KidsSummerCampRegistrationSuccess.jsx";



import SecretOfSuccess from "./pages/SecretOfSuccess";
import BhagavadGita from "./pages/BhagavadGita";
import Wallpapers from "./pages/Wallpaper";
import LifeMembership from "./pages/LifeMembership";
import Campaign from "./pages/Campaign";
import JoinFamily from "./pages/JoinFamily";
import CertificateView from "./pages/CertificateView";
import CampaignDonationForm from "./pages/CampaignDonationForm";
import PublicThankyou from "./pages/PublicThankyou";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import UpdateDetails from "./pages/UpdateDetails";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./protected-route/ProtectedRoute";
import UpdateProfile from "./pages/UpdateProfile";
import LiveVideo from "./pages/LiveVideo";
import Sitemap from "./pages/Sitemap";
import Campaigns from "./pages/Campaigns";
import JoinFamilyThankYou from "./pages/JoinFamilyThankYou";
import ShopList from "./pages/ShopList";
import ShopCheckout from "./pages/ShopCheckout";
import ShopThankYou from "./pages/ShopThankYou";
import CSR from "./pages/CSR.jsx";
import AppContext from "./context/AppContext";
import CsrNew from "./pages/CsrNew";
import RecentEvents from "./pages/RecentEvents";
import DailyDarshan from "./pages/DailyDarshan";
import EventDetails from "./pages/Event-Details";
import AllTransactions from "./pages/AllTransactions";
import ServiceRequests from "./pages/ServiceRequests";
import GovindaCafe from "./pages/GovindaCafe.jsx";
import TemplePhotoList from "./pages/TemplePhotoList.jsx";
import TempleVideoList from "./pages/TempleVideoList.jsx";
import LiveDarshan from "./pages/LiveVideo.jsx";
import NityaSeva from "./pages/NiyaSeva.jsx";
import GauraPurima from "./pages/GauraPurima";
import Repayment from "./pages/Repayment";
import RecurringPolicy from "./pages/RecurringPolicy";
import MsKmCampaign from "./pages/MsKmCampaign.jsx";


const App = () => {
  const [opened, setOpened] = useState(false);
  const { showHeaderFooter } = useContext(AppContext);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      document.oncontextmenu = function () {
        return false;
      };
      document.onselectstart = function (event) {
        if (
          event.srcElement.type !== "text" &&
          event.srcElement.type !== "textarea" &&
          event.srcElement.type !== "password"
        ) {
          return false;
        } else {
          return true;
        }
      };
      document.ondragstart = function () {
        return false;
      };
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        {showHeaderFooter && <Header setOpened={setOpened} />}
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/donate-categories" element={<Donate />}></Route>
          <Route exact path="/donate" element={<Campaigns />}></Route>
          <Route exact path="/festivals" element={<Festivals />}></Route>
          <Route path="/gaura-purima" element={<GauraPurima />} />
          <Route
            exact
            path="/donation-form/:slug"
            element={<DonationForm />}
          ></Route>
          <Route exact path="/donation-form" element={<DonationForm />}></Route>
          <Route
            exact
            path="/nitya-seva"
            element={<NityaSevaForm />}
          ></Route>

          <Route exact path="/movement" element={<Movement />}></Route>
          <Route exact path="/services" element={<Services />}></Route>
          <Route exact path="/yatras" element={<Yatras />}></Route>
          <Route
            exact
            path="/Recent-Events/:slug"
            element={<RecentEvents />}
          ></Route>
          <Route exact path="/Recent-Events" element={<RecentEvents />}></Route>
          <Route
            exact
            path="/All-Transactions/:slug"
            element={<RecentEvents />}
          ></Route>
          <Route
            exact
            path="/All-Transactions"
            element={<AllTransactions />}
          ></Route>
          <Route
            exact
            path="/Service-requests/:slug"
            element={<ServiceRequests />}
          ></Route>
          <Route
            exact
            path="/Service-requests"
            element={<ServiceRequests />}
          ></Route>
          <Route
            exact
            path="/Event-Details/:id/"
            element={<EventDetails />}
          ></Route>
          <Route
            exact
            path="/Daily-Darshan/:slug"
            element={<DailyDarshan />}
          ></Route>
          <Route exact path="/Daily-Darshan" element={<DailyDarshan />}></Route>
          <Route
            exact
            path="/thank-you/:id/:type"
            element={<ThankYou />}
          ></Route>
          <Route
            exact
            path="/payment-failed"
            element={<PaymentFailed />}
          ></Route>
          <Route
            exact
            path="/purchase-failed"
            element={<PurchaseFailed />}
          ></Route>
          <Route exact path="/about-annadaan" element={<FoodForLife />}></Route>
          <Route exact path="/connect" element={<Contact />}></Route>
          <Route exact path="/about-us" element={<AboutUs />}></Route>
          <Route
            exact
            path="/gaudiya-vaishnavism"
            element={<GaudiaPage />}
          ></Route>
          <Route exact path="/volunteer" element={<Volunteer />}></Route>
          <Route exact path="/courses" element={<Courses />}></Route>
          <Route exact path="/krishna-quiz" element={<KrishnaQuiz />}></Route>
          <Route exact path="/kids-summer-camp" element={<KidsSummerCampRegistration />}></Route>
          <Route exact path="/summer-camp-registration-success" element={<KidsSummerCampRegistrationSuccess />}></Route>
          <Route path="/quiz-registration-success" element={<KrishnaQuizRegistrationSuccess />} />
          <Route
            exact
            path="/secret-of-success"
            element={<SecretOfSuccess />}
          ></Route>
          <Route exact path="/bhagavad-gita" element={<BhagavadGita />}></Route>
          <Route exact path="/wallpapers" element={<Wallpapers />}></Route>
          <Route
            exact
            path="/life-membership"
            element={<LifeMembership />}
          ></Route>
          {/* <Route exact path="/campaigns" element={<Campaigns/>}></Route> */}
          <Route exact path="/:slug" element={<Campaign />}></Route>
          <Route
            exact
            path="/campaign-donation/:slug/:amount"
            element={<CampaignDonationForm />}
          ></Route>
          <Route exact path="/join-family" element={<JoinFamily />}></Route>
          <Route
            exact
            path="/certificate-view/:id"
            element={<CertificateView />}
          ></Route>
          <Route exact path="/thank-you" element={<PublicThankyou />}></Route>
          <Route
            exact
            path="/terms-and-condition"
            element={<TermsAndCondition />}
          ></Route>
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          ></Route>
          <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
          <Route exact path="/recurring-payments-terms" element={<RecurringPolicy />}></Route>

          <Route
            exact
            path="/update-details"
            element={<UpdateDetails />}
          ></Route>
          <Route
            exact
            path="/dashboard"
            element={
              <ProtectedRoute>
                {" "}
                <Dashboard />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/update-profile"
            element={
              <ProtectedRoute>
                {" "}
                <UpdateProfile />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/update-profile/:id"
            element={
              <ProtectedRoute>
                {" "}
                <UpdateProfile />
              </ProtectedRoute>
            }
          ></Route>
          <Route exact path="/watch-live" element={<LiveVideo />}></Route>
          <Route exact path="/sitemap" element={<Sitemap />}></Route>
          <Route
            exact
            path="/join-family-thank-you"
            element={<JoinFamilyThankYou />}
          ></Route>

          <Route path="/80g-download/:id" element={<Download80G />} />
          <Route path="/shop" element={<ShopList />} />
          <Route path="/shop-checkout" element={<ShopCheckout />} />
          <Route path="/shop-checkout/:id" element={<ShopCheckout />} />
          <Route path="/purchase-success/:id" element={<ShopThankYou />} />
          {/* <Route path="/csr" element={<CSR />} /> */}
          <Route path="/csr" element={<CsrNew />} />
          <Route path="/makar-kumbh" element={<MsKmCampaign />} />
          <Route path="/makar-kumbh-annadaan" element={<MsKmCampaign />} />
          <Route path="/makar-sankranti-annadaan" element={<MsKmCampaign />} />
          <Route path="/makar-sankranti-daan" element={<MsKmCampaign />} />

          <Route path="/govinda-cafe" element={<GovindaCafe />} />
          <Route path="/temple_photolist" element={<TemplePhotoList />} />
          <Route path="/temple_videolist" element={<TempleVideoList />} />
          <Route path="/live_darshan" element={<LiveDarshan />} />
          {/* <Route path="/nitya_seva_subscribe" element={<NityaSeva />} /> */}
          <Route path="/payment-due/:id" element={<Repayment />} />
          <Route path="/login" element={<Donorlogin />} />

          <Route exact path="/404" element={<Campaigns />}></Route>
          <Route path="*" element={<Campaigns />} />


        </Routes>
        {showHeaderFooter && <Footer />}

        <SideNav opened={opened} setOpened={setOpened} />
      </Router>
    </HelmetProvider>
  );
};

export default App;
