import React, { useRef, useState, useEffect } from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import notification from "../helpers/notification";
import axios from "axios";
import ApiService from "../api/ApiService";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const KidsSummerCampRegistration = () => {
    const [loading, setLoading] = useState(false);
    const reCaptChaRef = useRef();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            amount: 500,
        },
    });

    const fetchStateCity = async (pin) => {
        try {
            let url = `https://api.postalpincode.in/pincode/${pin}`;

            let config = {
                method: "GET",
                url,
            };
            const { data } = await axios(config);

            let cityVal = '';
            if (data[0].PostOffice.length > 1) {
                data[0].PostOffice.map(ct => {
                    if (ct.Block !== 'NA') {
                        return cityVal = ct.Block
                    }
                })
            } else {
                cityVal = data[0].PostOffice[0].Block;
            }

            if (data[0].Status === "Success") {
                setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
                setValue("state", data[0].PostOffice[0].State);
            } else {
                notification("warning", "Enter valid PIN code!");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleRegistration = async (values) => {
        try {
            setLoading(true);

            const token = await reCaptChaRef.current.executeAsync();
            values.token = token;
            values.amount = 500;
            values.quizContest = "Kids Summer Camp 2025";
            console.log("Values", values);
            const res = await ApiService.registerKidsSummerCamp(values);

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                currency: res.currency,
                amount: res.amount,
                name: "ISKCON Attapur",
                description: "KRISHNA KARNAMRITAM Kids Summer Camp 2025 Registration",
                image: `${process.env.REACT_APP_BACKEND_URL}/assets/img/logo.png`,
                order_id: res.id,
                handler: function (response) {
                    navigate('/summer-camp-registration-success', { replace: true });
                },
                prefill: {
                    name: values.childName,
                    email: values.email,
                    contact: values.phone,
                },
                notes: {
                    type: "krishna-quiz",
                },
                theme: {
                    color: "#FFD370",
                },
                modal: {
                    ondismiss: async function () {
                        const cancelPurchase = await ApiService.cancelPurchase(res.id);
                    },
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

            paymentObject.on("payment.failed", function (response) {
                navigate(`/purchase-failed?id=${res.shopId}`, { replace: true });
            });

            setLoading(false);
            reCaptChaRef.current.reset();
        } catch (error) {
            setLoading(false);
            console.log(error);
            notification("error", error.response?.data?.message || "Registration failed");
        }
    };

    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = () => resolve(true);
                script.onerror = () => resolve(false);
                document.body.appendChild(script);
            });
        };

        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, []);

    return (
        <>
            <MetaData title="KRISHNA KARNAMRITAM Kids Summer Camp 2025 - ISKCON Attapur" />
            <PageHeader
                title="KRISHNA KARNAMRITAM Kids Summer Camp 2025"
                imgSrc="/images/page-header/24.jpg"
            />
            <section className="donation-form-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">KRISHNA KARNAMRITAM Kids Summer Camp 2025 Registration</h2>
                                <p className="b-line">
                                    Register for the Kids Summer Camp 2025 and let your child experience spiritual learnings and happiness that never end!
                                </p>
                            </div>
                            <div className="quiz-details mt-4">
                                <p>✨🌸 <strong>Hare Krishna, lovely Kids!</strong> 🌸✨</p>
                                <p>
                                    We're so excited to announce that <strong>KRISHNA KARNAMRITAM</strong> is back with Kids Summer Camp 2025!! ✨🌈 Let this summer begin with Spiritual learnings and happiness that never end.⭐
                                </p>
                                <h4>📋 Camp Details</h4>
                                <ul>
                                    <li><strong>Eligibility:</strong> Children of age 6Yrs - 12Yrs</li>
                                    <li>
                                        <strong>Class Schedule:</strong> Starting from 28th April to 23rd May<br />
                                        Monday - Friday @ 11AM - 12:30PM
                                    </li>
                                    <li><strong>Mode:</strong> Online class via ZOOM (Link will be shared accordingly)</li>
                                </ul>
                                <h4>✨ Highlights:</h4>
                                <p>We've planned a bunch of exciting activities this time which would help the child to rejoice and learn:</p>
                                <ul>
                                    <li>Stories</li>
                                    <li>Art & Craft 🎨</li>
                                    <li>Bhagvadgita 12th chapter learning 📖</li>
                                    <li>Chanting the Holy name everyday 📿</li>
                                    <li>Kirtan Yoga</li>
                                    <li>Dasavatar stotra 🎼 and many more fun activities</li>
                                </ul>
                                <h4>🎁 Special Invitation:</h4>
                                <p>
                                    💎 Participants will be invited to <strong>ISKCON ATTAPUR</strong> temple on completion of the camp. There we will conduct spiritual programs and children will be awarded with the <strong>gracious and merciful gifts</strong>.🎁🏆🪄🎊
                                </p>
                            </div>
                        </div>

                    </div>
                    <form className="shop-form mt-5" onSubmit={handleSubmit(handleRegistration)}>
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="donate-img">
                                    <figure className="mb-0">
                                        <img src="/images/common/summer-camp.webp" alt="Kids Summer Camp 2025" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-lg-8 mt-lg-0 mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="childName">Child's Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Child's Name"
                                                {...register("childName", { required: true })}
                                            />
                                            {errors.childName?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="childAge">Child's Age (6Yrs-12Yrs)</label>
                                            <input
                                                type="number"
                                                placeholder="Enter Child's Age"
                                                min="6"
                                                max="12"
                                                {...register("childAge", {
                                                    required: true,
                                                    min: {
                                                        value: 6,
                                                        message: "Child must be at least 6 years old"
                                                    },
                                                    max: {
                                                        value: 12,
                                                        message: "Child must not be older than 12 years"
                                                    }
                                                })}
                                            />
                                            {errors.childAge?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                            {errors.childAge && (
                                                <span className="error text-danger">{errors.childAge.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="fatherName">Father's Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Father's Name"
                                                {...register("fatherName", { required: true })}
                                            />
                                            {errors.fatherName?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="motherName">Mother's Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Mother's Name"
                                                {...register("motherName", { required: true })}
                                            />
                                            {errors.motherName?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="gender">Gender</label>
                                            <select
                                                {...register("gender", { required: true })}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                            {errors.gender?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="classStudying">Class you're studying in</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Class/Grade"
                                                {...register("classStudying", { required: true })}
                                            />
                                            {errors.classStudying?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="phone">Contact Number for WhatsApp group</label>
                                            <input
                                                type="tel"
                                                placeholder="Enter Contact Number"
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("phone", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^[6-9]\d*$/,
                                                        message: "Please provide valid phone number",
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "Phone number must be minimum 10 digit",
                                                    },
                                                })}
                                            />
                                            {errors.phone?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                            {errors.phone && (
                                                <span className="error text-danger">{errors.phone.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="email">Email (Optional)</label>
                                            <input
                                                type="email"
                                                placeholder="Enter Your Email"
                                                {...register("email", {
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Please enter a valid email",
                                                    },
                                                })}
                                            />
                                            {errors.email && <span className="error text-danger">{errors.email.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="address">Address</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Your Address"
                                                {...register("address", { required: true })}
                                            />
                                            {errors.address?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="pin">PIN</label>
                                            <input
                                                type="text"
                                                minLength={6}
                                                maxLength={6}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("pin", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^\d+$/,
                                                        message: "PIN code must be number.",
                                                    },
                                                    onChange: (e) => {
                                                        if (e.target.value.length === 6) {
                                                            fetchStateCity(e.target.value);
                                                        }
                                                    },
                                                })}
                                                placeholder="PIN"
                                            />
                                            {errors.pin?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                            {errors.pin && (
                                                <small className="text-danger error">{errors.pin.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-part">
                                            <label htmlFor="city">City you live in</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Your City"
                                                {...register("city", { required: true })}
                                            />
                                            {errors.city?.type === "required" && (
                                                <span className="error text-danger">This is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-part">
                                            <label htmlFor="">State</label>
                                            <input
                                                type="text"
                                                placeholder="State"
                                                {...register('state')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                            ref={reCaptChaRef}
                                            size="invisible"
                                        />
                                    </div>
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                                        >
                                            {loading ? (
                                                <div className="d-flex align-items-center">
                                                    <div className="spinner-border text-light" role="status"></div>
                                                    <div className="ms-2">REGISTERING...</div>
                                                </div>
                                            ) : (
                                                `PAY ₹${500}/-`
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default KidsSummerCampRegistration;